.userstable {
    color: white;
    text-align: center; 
}

.userstable td {
    padding: 10px;
}

.userstable td:not(:first-child) {
    border-left: 2px dotted white;
}